import { useMediaQuery } from "@mui/material"
import Layout from "components/layout"
import React, { useEffect } from "react"
import AppManager from "services/app-manager"
import { ListHeaderProviderPage } from "components/content/list-header"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import { Link, navigate } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Typography } from "components/typography"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "components/content/map-to-div"
import { providerImage } from "components/survey/utils"
import * as styles from "./style.module.scss"
import ReturnLinkChain from "components/content/return-link-chain"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const ProviderOverviewTemplate = (props: any) => {
  const { providerId, dateUpdated, ads, qa }: { providerId: string; dateUpdated: string; ads?: any; qa?: any } = JSON.parse(props.pageContext.p)
  const isTablet = !useMediaQuery("(min-width:1024px)")
  const isWide = useMediaQuery("(min-width:768px)")
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = dateUpdated.substring(8, 10) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)

  const provider = AppManager.getInstance().getProviderOverviewContentById(providerId)

  const chunkSize = isWide ? 4 : 2
  const providerOverviews = AppManager.getInstance().getAllProvidersOverviewContent()
  const mainItems = Object.values(providerOverviews)
    .filter((x: any) => x.big4 && x.popular && x.id !== provider.id)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const otherItems = Object.values(providerOverviews)
    .filter((x: any) => !x.big4 && x.popular && x.id !== provider.id)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const majors = chunkifyArray(mainItems, chunkSize)
  const popular = chunkifyArray(otherItems, chunkSize)

  const comparePagesView = () => {
    // let lowestRate = Number.MAX_VALUE
    // Object.values(hlProviders).forEach(provider => {
    //   Object.values(provider.products).forEach(product => {
    //     Object.values(product.rates).forEach(rate => {
    //       if (rate.interestRate < lowestRate) {
    //         lowestRate = rate.interestRate
    //       }
    //     })
    //   })
    // })

    // const [open, setOpen] = React.useState(false)

    const comparePages = [
      {
        title: "Home loans",
        includeKey: "hasHomeLoans",
        // description: "Rates from " + (lowestRate * 100).toLocaleString() + "%",
        description: "Top home loan rates",
        url: `/${provider.urlSlug}-home-loans`,
        imgUrl: "images/icon_home_loan.png",
      },
      {
        title: "Credit cards",
        includeKey: "hasCreditCards",
        description: "0% rates, rewards",
        url: `/${provider.urlSlug}-credit-cards`,
        imgUrl: "images/icon_credit_card.png",
      },
      {
        title: "Personal loans",
        includeKey: "hasPersonalLoans",
        description: "Top personal loan rates",
        url: `/${provider.urlSlug}-personal-loans`,
        imgUrl: "images/icon_personal_loan.png",
      },
      {
        title: "Car loans",
        includeKey: "hasCarLoans",
        description: "Top car loan rates",
        url: `/${provider.urlSlug}-car-loans`,
        imgUrl: "images/icon_car_loan.png",
      },
      {
        title: "Term deposits",
        includeKey: "hasTermDeposits",
        description: "Top term deposit rates",
        url: `/${provider.urlSlug}-term-deposit-rates`,
        imgUrl: "images/icon_term_deposit.png",
      },
      {
        title: "Bank accounts",
        includeKey: "hasBankAccounts",
        description: "Top everyday accounts",
        url: `/${provider.urlSlug}-bank-accounts`,
        imgUrl: "images/icon_transaction_account.png",
      },
      {
        title: "Savings accounts",
        includeKey: "hasSavingsAccounts",
        description: "High interest savings accounts",
        url: `/${provider.urlSlug}-savings-accounts`,
        imgUrl: "images/icon_savings_account.png",
      },
      {
        title: "Buy now pay later",
        includeKey: "hasBuyNowPayLater",
        description: "(coming soon)",
        url: `/${provider.urlSlug}-buy-now-pay-later`,
        imgUrl: "images/icon_buy_now_pay_later.png",
      },
    ]

    const offeredComparePages = comparePages.filter(p => "includeKey" in p && p["includeKey"] in provider && provider[p["includeKey"]])

    const pageCell = (page: { title: string; description: string; url: string; imgUrl: string; includeKey?: string }, style: React.CSSProperties = {}) => {
      return (
        <div
          className="category-link-cell-button"
          style={style}
          key={`page-cell-${page.title}`}
          onClick={() => {
            navigate(page.url)
          }}
        >
          <div className="category-link-cell-button-image-container">
            <LazyLoadImage style={{width: "100%"}} src={page.imgUrl} className="category-link-cell-button-image-container" />
          </div>
          <Typography.P comp="category-link-cell-button-text" style={{ maxWidth: "100%" }}>
            <div style={{ maxWidth: "100%", overflowX: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{page.title}</div>
          </Typography.P>
        </div>
      )
    }

    const makePageCellRows = (itemsPerRow: number) => {
      return (
        <div className="category-link-cell-grid" style={{ marginTop: isWide ? "64px" : "50px", gridTemplateColumns: `repeat(${itemsPerRow}, minmax(0,1fr))`, maxWidth: isWide && isTablet ? "none" : "none" }}>
          {offeredComparePages.map((p, i) => {
            return pageCell(p, { gridRow: 1 + Math.floor(i / itemsPerRow), gridColumn: 1 + (i % itemsPerRow) })
          })}
        </div>
      )
    }

    return <div style={isWide ? { marginBottom: "125px" } : { marginBottom: "60px" }}>{makePageCellRows(isWide ? (isTablet ? 4 : 4) : 2)}</div>
  }

  return (
    <Layout title={`${provider.name} | Stay or Go`} description={"TBD"} urlSlug={`${provider.urlSlug}`} dateUpdated={dateUpdated}>
      <div className="container-center">
        <div className="home-container">
          <div style={{ height: isWide ? "60px" : "30px" }} />
          <ReturnLinkChain linkChain={[{ name: "Providers", to: "/#providers" }]} currentLocationItem={{ name: provider.name }} excludeBottomMargin={isWide} includeFactCheckedDialog={isWide} factCheckedDialogDateUpdated={dateString} style={{ marginTop: "60px" }} />
          {/* {isWide ? <div className={styles.topOfPageLinksContainer}>
            <ReturnLinkChain
              linkChain={[
                { name: "Providers", to: "/#providers" }
              ]}
              currentLocationItem={{name: provider.name}}
              excludeBottomMargin
              includeFactCheckedDialog
              factCheckedDialogDateUpdated={dateString}
            />
            <FactCheckedDialog dateString={dateString} />
          </div> : <ReturnLinkChain
            linkChain={[
              { name: "Providers", to: "/#providers" }
            ]}
            currentLocationItem={{name: provider.name}}
            excludeBottomMargin
          />} */}
          <div className={styles.providerHeaderContainer}>
            <LazyLoadImage src={providerImage(provider.id)} style={{ margin: isWide ? "0 6.25%" : "0", width: isWide ? "20.833%" : "150px" }} alt={`${provider.name} logo`} />
            <div style={{ marginBottom: "45px" }}>
              <div className={styles.providerNameContainer}>
                <Typography.H3 comp="provider-page-bank-type">{provider.big4 ? "Major" : "Challenger"}</Typography.H3>
                <Typography.H2 comp="provider-page-title">{provider.name}</Typography.H2>
              </div>
              {provider.overview && (
                <div>
                  <Typography.H6 style={{ marginBottom: isWide ? "10px" : "5px" }} comp="provider-page-subheading">
                    Overview
                  </Typography.H6>
                  <Typography.P comp="provider-page-content">{provider.overview}</Typography.P>
                </div>
              )}
            </div>
            {!isWide && <FactCheckedDialog dateString={dateString} />}
          </div>
          {(provider.hasCreditCards || provider.hasHomeLoans || provider.hasPersonalLoans || provider.hasCarLoans || provider.hasTermDeposits || provider.hasBankAccounts || provider.hasSavingsAccounts) && (
            <div>
              <ListHeaderProviderPage provider={provider}>Products Offered</ListHeaderProviderPage>
              {comparePagesView()}
            </div>
          )}
          {(provider.advantages || provider.security || provider.service) && (
            <div>
              <ListHeaderProviderPage provider={provider}>Highlights</ListHeaderProviderPage>
              {provider.advantages && (
                <>
                  <Typography.H6 style={{ marginTop: "60px", marginBottom: isWide ? "15px" : "5px" }} comp="provider-page-subheading">
                    Advantages
                  </Typography.H6>
                  <Typography.P comp="provider-page-content" style={{ marginBottom: "45px" }}>
                    {provider.advantages}
                  </Typography.P>
                </>
              )}
              {provider.security && (
                <>
                  <Typography.H6 style={{ marginTop: "60px", marginBottom: isWide ? "15px" : "5px" }} comp="provider-page-subheading">
                    Security
                  </Typography.H6>
                  <Typography.P comp="provider-page-content" style={{ marginBottom: "45px" }}>
                    {provider.security}
                  </Typography.P>
                </>
              )}
              {provider.service && (
                <>
                  <Typography.H6 style={{ marginTop: "60px", marginBottom: isWide ? "15px" : "5px" }} comp="provider-page-subheading">
                    Customer Service
                  </Typography.H6>
                  <Typography.P comp="provider-page-content" style={{ marginBottom: "45px" }}>
                    {provider.service}
                  </Typography.P>
                </>
              )}
            </div>
          )}
          <ListHeaderProviderPage provider={provider}>Alternatives</ListHeaderProviderPage>
          <Typography.H6 style={{ marginTop: "60px", marginBottom: "10px" }} comp="provider-page-subheading">
            Majors
          </Typography.H6>
          <Typography.P comp="provider-page-content" style={{ marginBottom: "45px" }}>
            Big banks with extensive nationwide networks that provide a comprehensive range of services
          </Typography.P>
          <MapToDiv.Banks banks={majors} />
          <Typography.H6 style={{ marginTop: "60px", marginBottom: "10px" }} comp="provider-page-subheading">
            Challengers
          </Typography.H6>
          <Typography.P comp="provider-page-content" style={{ marginBottom: "45px" }}>
            Regional and international banking institutions that offer financial services
          </Typography.P>
          <MapToDiv.Banks banks={popular} />
        </div>
      </div>
    </Layout>
  )
}

export default ProviderOverviewTemplate
